// import Swiper, {Autoplay, Navigation, Pagination, Thumbs} from "swiper";
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, Thumbs } from 'swiper/modules';
import {gsap} from "gsap";
import {isMobile} from './utils';
import 'swiper/css/bundle';

Swiper.use([Autoplay, Navigation, Pagination, Thumbs]);

const productSlider = document.querySelectorAll('.product-image-gallery.swiper');
const relatedProductsSlider = document.querySelectorAll('.related-products-row.swiper');
const carouselSlider = document.querySelectorAll('.carousel-slider__slides.swiper');
const testimonialsSlider = document.querySelectorAll('.testimonials-block__content.swiper');
const logosSlider = document.querySelectorAll('.logos-block__slider.swiper');

productSlider.forEach(function (elem, i) {
    const thumbsSlider = new Swiper(".product-image-gallery-thumbs.swiper", {
        modules: [
            Thumbs
        ],
        spaceBetween: 8,
        slidesPerView: 'auto',
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
    });
    new Swiper(elem, {
        spaceBetween: 10,
        slidesPerView: 1,
        thumbs: {
            swiper: thumbsSlider,
        },
    })
    console.log(thumbsSlider);
})

carouselSlider.forEach(function (elem, i) {
    new Swiper(elem, {
        modules: [Autoplay],
        slidesPerView: 1.3,
        spaceBetween: 24,
        loop: true,
        centeredSlides: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            1024: {
                slidesPerView: 4.3,
            },
            1500: {
                slidesPerView: 5.6,
            },
            1921: {
                slidesPerView: 7.4,
            },
        },
    })
})

relatedProductsSlider.forEach(function (elem, i) {
    if (isMobile()) {
        new Swiper(elem, {
            modules: [Navigation],
            slidesPerView: 1,
            spaceBetween: 20,
            navigation: {
                nextEl: '.related-products-container .swiper-button-next',
                prevEl: '.related-products-container .swiper-button-prev',
            },
        })
    }
})

testimonialsSlider.forEach(function (elem, i) {
    new Swiper(elem, {
        modules: [Navigation],
        slidesPerView: 1,
        spaceBetween: 15,
        loop: true,
        navigation: {
            nextEl: '.testimonials-block .swiper-button-next',
            prevEl: '.testimonials-block .swiper-button-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
        },
    })
})

logosSlider.forEach(function (elem, i) {
    new Swiper(elem, {
        modules: [Navigation],
        slidesPerView: 2,
        spaceBetween: 20,
        navigation: {
            nextEl: '.logos-block .swiper-button-next',
            prevEl: '.logos-block .swiper-button-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 4,
            },
            1024: {
                slidesPerView: 7,
            },
        },
    })
})