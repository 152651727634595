document.addEventListener('DOMContentLoaded', function() {
    const tabButtons = document.querySelectorAll('.tab-button');
    const tabPanels = document.querySelectorAll('.tab-panel');
    const mobileSelect = document.querySelector('.mobile-tab-select');
    const pageTitle = document.querySelector('.page-title h1');
    const breadcrumb = document.querySelector('.breadcrumbs li:last-child');

    function activateTab(tabId) {
        // Only update URL if tabs exist on the page
        if (tabButtons.length > 0) {
            history.pushState({}, '', `?tab=${tabId}`);
        }

        // Update tabs
        tabButtons.forEach(btn => {
            const isActive = btn.id === 'tab-' + tabId;
            btn.classList.toggle('active', isActive);
            btn.setAttribute('aria-selected', isActive);
        });
        
        // Update panels
        tabPanels.forEach(panel => {
            const isActive = panel.id === 'panel-' + tabId;
            panel.classList.toggle('active', isActive);
        });
        
        // Update select value
        if (mobileSelect) {
            mobileSelect.value = tabId;
        }
        
        // Update titles
        const activeButton = document.querySelector('#tab-' + tabId);
        if (activeButton) {
            const newTitle = activeButton.textContent.trim();
            if (pageTitle) pageTitle.textContent = newTitle;
            if (breadcrumb) breadcrumb.textContent = newTitle;
            document.title = newTitle + ' | Your Site Name';
        }
    }

    // Handle tab clicks
    tabButtons.forEach(button => {
        button.addEventListener('click', function() {
            const tabId = this.id.replace('tab-', '');
            activateTab(tabId);
        });
    });

    // Handle mobile select change
    if (mobileSelect) {
        mobileSelect.addEventListener('change', function() {
            activateTab(this.value);
        });
    }

    // Handle browser back/forward
    window.addEventListener('popstate', function() {
        const params = new URLSearchParams(window.location.search);
        const tabId = params.get('tab') || 'account';
        activateTab(tabId);
    });

    // Check URL on page load - only if tabs exist
    if (tabButtons.length > 0) {
        const params = new URLSearchParams(window.location.search);
        const initialTab = params.get('tab') || 'account';
        activateTab(initialTab);
    }

    // Add new function to load order details
    function loadOrderDetails(orderNumber) {
        const ordersPanel = document.querySelector('#panel-orders');
        
        // Store the original content to allow going back
        if (!ordersPanel.getAttribute('data-original-content')) {
            ordersPanel.setAttribute('data-original-content', ordersPanel.innerHTML);
        }

        // Add fade-out effect
        ordersPanel.style.opacity = '0';
        ordersPanel.style.transition = 'opacity 0.3s ease';

        // Create a loading timeout
        let loadingTimeout;

        // Wait for fade out, then start loading
        setTimeout(() => {
            loadingTimeout = setTimeout(() => {
                ordersPanel.innerHTML = '<div class="loading">Loading order details...</div>';
                ordersPanel.style.opacity = '1';
            }, 300); // Only show loading if fetch takes longer than 300ms

            fetch(`/shop/customer/_includes/order?number=${orderNumber}&ajax=1`, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
                .then(response => response.text())
                .then(html => {
                    // Clear loading timeout if it hasn't triggered yet
                    clearTimeout(loadingTimeout);
                    
                    // Fade out again before showing new content
                    ordersPanel.style.opacity = '0';
                    
                    setTimeout(() => {
                        ordersPanel.innerHTML = html;
                        
                        // Add back button
                        const backButton = document.createElement('button');
                        backButton.className = 'btn btn-secondary mb-10';
                        backButton.innerHTML = '<span>← Back to Orders</span>';
                        backButton.addEventListener('click', () => {
                            // Add fade effect for back button too
                            ordersPanel.style.opacity = '0';
                            setTimeout(() => {
                                ordersPanel.innerHTML = ordersPanel.getAttribute('data-original-content');
                                ordersPanel.style.opacity = '1';
                            }, 300);
                        });
                        
                        ordersPanel.insertBefore(backButton, ordersPanel.firstChild);
                        ordersPanel.style.opacity = '1';
                    }, 300);
                })
                .catch(error => {
                    // Clear loading timeout if it hasn't triggered yet
                    clearTimeout(loadingTimeout);
                    
                    console.error('Error loading order:', error);
                    ordersPanel.innerHTML = '<div class="error">Error loading order details</div>';
                    ordersPanel.style.opacity = '1';
                });
        }, 300);
    }

    // Add click handler for order links
    document.addEventListener('click', function(e) {
        const orderLink = e.target.closest('[data-order-number]');
        if (orderLink) {
            e.preventDefault();
            const orderNumber = orderLink.getAttribute('data-order-number');
            
            // Activate orders tab if not already active
            activateTab('orders');
            
            // Load order details
            loadOrderDetails(orderNumber);
        }
    });

    // Add handler for favorite button clicks
    document.addEventListener('click', function(e) {
        const favoriteButton = e.target.closest('.js-favourite-button');
        if (favoriteButton) {
            e.preventDefault();
            console.log('sup');
            
            
            // Get the closest favorite item container
            const favoriteItem = favoriteButton.closest('.favourites__item');
            if (!favoriteItem) return;

            const itemId = favoriteItem.dataset.itemId;
            
            // Add loading state
            favoriteButton.classList.add('loading');
            
            // Get the form data from the button's parent form
            const form = favoriteButton.closest('form');
            const formData = new FormData(form);

            fetch(form.action, {
                method: 'POST',
                body: formData,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    // Fade out and remove the item
                    favoriteItem.style.opacity = '0';
                    favoriteItem.style.transition = 'opacity 0.3s ease';
                    
                    setTimeout(() => {
                        favoriteItem.remove();
                        
                        // Check if there are any items left
                        const remainingItems = document.querySelectorAll('.favourites__item');
                        if (remainingItems.length === 0) {
                            const container = document.querySelector('.favourites__items');
                            container.innerHTML = '<div class="favourites__empty"><p>You have no favourites yet.</p></div>';
                        }
                    }, 300);
                }
            })
            .catch(error => {
                console.error('Error removing favorite:', error);
            })
            .finally(() => {
                favoriteButton.classList.remove('loading');
            });
        }
    });
});