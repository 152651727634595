document.addEventListener('DOMContentLoaded', function() {
    const form = document.querySelector('.product-variants-form');
    
    const totalUnitsSpan = document.querySelector('.total-units');
    const totalPriceSpan = document.querySelector('.total-price');
    const totalQuantitySpan = document.querySelector('.total-quantity');
    
    function updateTotals() {
        const quantityInputs = form.querySelectorAll('.quantity-input');
        let totalUnits = 0;
        let totalPrice = 0;
        let totalQuantity = 0;
        
        quantityInputs.forEach(input => {
            const quantity = parseInt(input.value) || 0;
            const units = parseInt(input.dataset.units) || 0;
            const price = parseFloat(input.dataset.price) || 0;
            
            totalUnits += units * quantity;
            totalPrice += price * quantity;
            totalQuantity += quantity;
        });
        
        totalUnitsSpan.textContent = totalUnits;
        totalPriceSpan.textContent = formatPrice(totalPrice);
        totalQuantitySpan.textContent = totalQuantity;
    }
    
    function formatPrice(price) {
        return new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: 'AUD'
        }).format(price);
    }
    
    if (form) {
        form.addEventListener('input', function(e) {
            if (e.target.classList.contains('quantity-input')) {
                console.log('input');
                updateTotals();
            }
        });
        form.addEventListener('click', function(e) {
            console.log(e.target);
            if (e.target.closest('.quantity-btn')) {
                console.log('click');
                setTimeout(updateTotals, 0);
            }
        });
        // Initial calculation
        updateTotals();
    }

    // Add favorites functionality
    document.querySelectorAll('.favourites-button-container').forEach(container => {
        const button = container.querySelector('.favourite-button');
        // Check if we're in the favorites panel
        const isInFavoritesPanel = container.closest('.favourites') !== null;
        
        button.addEventListener('click', function() { 
            const data = {
                elementId: container.dataset.variantId,
                elementSiteId: container.dataset.siteId,
                action: container.dataset.action
            };

            // Add CSRF token
            data[container.dataset.csrfName] = container.dataset.csrfToken;

            fetch('/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: new URLSearchParams(data)
            })
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    // Toggle button state
                    button.classList.toggle('active');
                    
                    // Update action for next click
                    container.dataset.action = container.dataset.action === 'wishlist/items/add' 
                        ? 'wishlist/items/remove' 
                        : 'wishlist/items/add';


                    // If in favorites panel and removing item, remove the product card from view
                    if (isInFavoritesPanel && container.dataset.action === 'wishlist/items/add') {
                        const productCard = container.closest('.favourites__item');
                        if (productCard) {
                            productCard.style.opacity = '0';
                            setTimeout(() => {
                                productCard.remove();
                                
                                // Check if there are any remaining favorite items
                                const remainingItems = document.querySelectorAll('.favourites__item');
                                if (remainingItems.length === 0) {
                                    const favouritesContainer = document.querySelector('.favourites');
                                    if (favouritesContainer) {
                                        const emptyMessage = document.createElement('p');
                                        emptyMessage.textContent = "You have no favourites yet";
                                        emptyMessage.className = 'favourites-empty-message';
                                        favouritesContainer.appendChild(emptyMessage);
                                    }
                                }
                            }, 300); // Smooth transition before removal
                        }
                    }
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
        });
    });
});